import React, { useContext, useEffect } from "react"
import { ContextDispatch } from "./../../context/Context"

//Components
import Footer from "../../components/footer/footer"
import Hero from "../../components/hero/hero-left"
import Attendees from "../../components/attendees/attendees"
import Location from "../../components/location/location"
import AttendeesLocation from "../../components/attendees-location/attendees-location"
import CenteredLink from "../../components/centered-link/centered-link"
import Companies from "../../components/companies/companies"
import ListImage from "../../components/list-image/list-image"
import Speakers from "../../components/speakers/speakers"
import Gallery from "../../components/gallery/gallery"
import Summary from "../../components/summary/summary"
import ContactBar from "../../components/contact-bar/contact-bar"
import NextPage from "../../components/next-page/next-page"
import SEO from "../../components/seo/seo"

//Styles
import styles from "./styles.module.scss"

const PageTemplate4 = props => {
  const page = props,
    templateData = page.data.contentfulTemplate4



  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      storeDispatch({ type: "HEADER_DARK" })
    }
    header()
  }, [storeDispatch])

  let companiesblock,
    linkblock,
    purposeblock,
    topicsblock,
    attendeesblock,
    locationblock,
    speakersblock,
    summaryblock,
    galleryblock,
    contactBarblock,
    nextpageblock

  if (templateData.companies) {
    companiesblock = (
      <Companies data={templateData.companies} location={`innovation`} />
    )
  }

  if (templateData.purposeText) {
    purposeblock = (
      <ListImage
        title={templateData.purposeTitle}
        copy={templateData.purposeText}
        image={templateData.purposeImage}
        listItems={templateData.purposeList}
        bgColour={`#2431b3`}
        textAlignment={`right`}
        listType={`number`}
      />
    )
  }

  if (templateData.summitTopicsTitle) {
    topicsblock = (
      <ListImage
        title={templateData.summitTopicsTitle}
        image={templateData.summitTopicsImage}
        listItems={templateData.summitTopics}
        bgColour={`#172138`}
        textAlignment={`left`}
      />
    )
  }

  if (templateData.attendeesText) {
    attendeesblock = (
      <Attendees
        title={templateData.attendeesTitle}
        copy={templateData.attendeesText}
        listItems={templateData.attendeesList}
      />
    )
  }

  if (templateData.locationTitle) {
    locationblock = (
      <Location
        title={templateData.locationTitle}
        image={templateData.locationImage}
        label={templateData.locationLabelRich}
      />
    )
  }

  if (templateData.speakers) {
    speakersblock = (
      <div className={styles.borderTop}>
        <Speakers
          title={templateData.speakersTitle}
          text={templateData.speakersTextRich}
          data={templateData.speakers}
        />
      </div>
    )
  }

  if (templateData.summaryText) {
    summaryblock = (
      <Summary
        title={templateData.summaryTitle}
        copy={templateData.summaryText}
      />
    )
  }

  if (templateData.galleryImages) {
    galleryblock = <Gallery data={templateData.galleryImages} />
  }

  if (templateData.contactText) {
    contactBarblock = (
      <ContactBar
        title={templateData.contactTitle}
        copy={templateData.contactText}
        email={templateData.contactEmail}
      />
    )
  }

  if (templateData.contactLink) {
    linkblock = <CenteredLink data={templateData.contactLink} />
  }

  if (templateData.nextPage) {
    nextpageblock = (
      <NextPage
        heading={templateData.nextPage.heading}
        title={templateData.nextPage.linkTitle}
        url={`/` + templateData.nextPage.page.slug}
      />
    )
  } else {
    nextpageblock = (
      <NextPage
        heading={`Ready To Talk?`}
        title={`Get In Touch`}
        url={``}
        contact={true}
      />
    )
  }

  return (
    <React.Fragment>
      <SEO
        title={templateData.seoData.seoTitle}
        image={templateData.seoData.seoImage}
        description={templateData.seoData.seoDescription}
      />
      <Hero
        imageAlign={`left`}
        heroData={templateData}
        colourScheme={"light"}
      />
      {companiesblock}
      {purposeblock}
      {topicsblock}
      <AttendeesLocation
        attendeesblock={attendeesblock}
        locationblock={locationblock}
      />
      {speakersblock}
      {summaryblock}
      {galleryblock}
      {contactBarblock}
      {linkblock}
      {nextpageblock}
      <Footer />
    </React.Fragment>
  )
}

export default PageTemplate4

export const pageQuery = graphql`
  query template4NewPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTemplate4(slug: { eq: $slug }) {
      ...template4Query
    }
  }
`
